<template>
    <dv-full-screen-container class="bg" style="width: 100%; height: 100%">
        <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" title="净物打包" />
        <van-tabs v-model:active="tab" :swipeable="true" @change="onClickTab">
            <!--  -->
            <van-tab title="所有"></van-tab>
            <van-tab title="最近"></van-tab>
        </van-tabs>
        <div :style="{ height: boxH + 'px', position: 'relative' }">
            <img v-if="showFlag&&swipeIndex" @click="clickBack" class="pageBtn" src="@/assets/back.png" alt="" srcset="" style="left: 0.1rem;" />
            <van-swipe @change="onChange" :style="{ height: boxH + 'px' }" ref="swipe" :duration="null">
                <van-swipe-item v-for="item in swipeList" :key="item">
                    <ul>
                        <li class="hotelItem" v-for="item in hotel_arr" :key="item">
                            <div v-if="item != 'covering'" @click="toLink(item)">
                                <span style="margin:auto;">{{ item.hotelName }}</span>
                            </div>
                        </li>
                    </ul>
                </van-swipe-item>
            </van-swipe>
            <img v-if="showFlag && swipeIndex != (swipeList-1)" @click="clickNext" class="pageBtn" src="@/assets/next.png" alt="" srcset="" style="right: 0.1rem;" />
        </div>

        <van-tabbar v-model="active" fixed :placeholder="true">
            <van-tabbar-item @click="checkTabbar('all')">全部</van-tabbar-item>
            <van-tabbar-item @click="checkTabbar(item.arr)" v-for="item in classifyArr" :key="item.firstCharLetter">
                {{ item.firstCharLetter }}({{ item.arr.length }})</van-tabbar-item>
        </van-tabbar>
    </dv-full-screen-container>
</template>

<script>
import { ref, onMounted,getCurrentInstance } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { Col, Row, Icon, NavBar, Tab, Tabs, Swipe, SwipeItem, Toast, Tabbar, TabbarItem } from 'vant';
import api from "@/utils/api";
export default {
    // name: "factory_deskpack",
    components: {
        [Col.name]: Col,
        [Row.name]: Row,
        [Icon.name]: Icon,
        [NavBar.name]: NavBar,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem,
        [Toast.name]: Toast,
    },
    setup() {
        const { proxy } = getCurrentInstance()

        //自定义参数
        const hotelAll = ref([])
        const hotel = ref([])
        const hotel_arr = ref([])
        const swipeList = ref([])
        const active = ref(0);
        const tab = ref(0);
        const boxH = ref(0)
        const factory_code = ref('')//工厂code
        const classifyArr = ref([])//归类数组
        const swipeIndex = ref(0)
        const showFlag = ref(false)

        //router相关
        const route = useRoute()
        const router = useRouter()
        // 屏幕高度
        const windowHeight = ref(0)

        /* 点击返回按钮 */
        const onClickLeft = () => {
            history.back()
        }


        /* 轮播组件change事件 */
        const onChange = (index) => {
            swipeIndex.value = index
            hotel_arr.value = hotel.value.slice(index * 12, (index + 1) * 12)
            if (hotel_arr.value.length < 12) {
                let num = 12 - hotel_arr.value.length
                for (let i = 0; i < num; i++) {
                    hotel_arr.value.push('covering')
                }
            }
            // Toast('当前 Swipe 索引：' + swipeIndex.value)
        };
        /* 选中酒店跳转详情 */
        const toLink = (item) => {
            item.device_code = route.query.device_code
            item.staff_type = route.query.staff_type
            item.staff_code = route.query.staff_code
            router.push({
                path: '/factory/hotel_linen',
                query: item
            })
        }
        /* 获取工厂信息 */
        const getFactoryInfo = () => {
            console.log(route.query)
            api.getFactoryInfo(route.query.device_code).then((data) => {
                console.log(data.data)
                const res = data.data
                if (res.code == 200) {
                    factory_code.value = res.data.factory_code
                    getHotelInfo()
                }
            })
        }
        /* 获取工厂用户信息 */
        const getHotelInfo = () => {
            api.getHotelInfo(factory_code.value).then(data => {
                const res = data.data
                if (res.code == 200) {
                    console.log("222")
                    /* for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        hotel.value.push(element)
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        hotel.value.push(element)
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        hotel.value.push(element)
                    } */
                    hotelAll.value = res.data
                    hotel.value = res.data
                    swipeList.value = hotel.value.length > 12 ? Math.ceil(hotel.value.length / 12) : 1
                    onChange(0)//首次加载第一页内容
                    formateData(res.data)
                }
            })
        }
        /* 获取工厂最近打包用户信息 */
        const getrecentPackInfo = () => {
            api.getrecentPackInfo(factory_code.value).then(data => {
                const res = data.data
                if (res.code == 200) {
                    hotelAll.value = res.data
                    hotel.value = res.data
                    swipeList.value = hotel.value.length > 12 ? Math.ceil(hotel.value.length / 12) : 1
                    onChange(0)//首次加载第一页内容
                    formateData(res.data)
                }
            })
        }
        /* tabs点击切换事件 */
        const onClickTab = (index) => {
            console.log(index)
            switch (index) {
                case 0:
                    getHotelInfo()
                    break;
                case 1:
                    getrecentPackInfo()
                    break;
            }
        };


        /* 数组归类 */
        const formateData = (data) => {
            let m = {}
            for (let i = 0; i < data.length; i++) {
                let t = data[i]
                if (!m[t.firstCharLetter]) {
                    m[t.firstCharLetter] = []
                }
                m[t.firstCharLetter].push(t)
            }
            console.log(m)
            let arr = []
            for (let key in m) {
                arr.push({ firstCharLetter: key, arr: m[key] })
            }
            // console.log(arr)
            classifyArr.value = arr
        }

        /* 点击分类 */
        const checkTabbar = (arr) => {
            if (arr == 'all') {
                hotel.value = hotelAll.value
                onChange(0)
            } else {
                hotel.value = arr
                onChange(0)
            }
        }
        /* 上一页/下一页 */
        const clickBack = ()=>{
            proxy.$refs.swipe.prev()
        }
        const clickNext = ()=>{
            proxy.$refs.swipe.next()
        }



        // 生命周期
        onMounted(() => {
            windowHeight.value = window.innerHeight//获取屏幕高度
            // window.addEventListener('resize', getWindowResize)
            setTimeout(() => {
                console.log(document.getElementsByClassName('van-nav-bar__content')[0].clientHeight)
                console.log(document.getElementsByClassName('van-tab')[0].clientHeight)
                console.log(document.getElementsByClassName('van-tabbar')[0].clientHeight)
                let n = document.getElementsByClassName('van-nav-bar__content')[0].clientHeight
                let t = document.getElementsByClassName('van-tab')[0].clientHeight
                let r = document.getElementsByClassName('van-tabbar')[0].clientHeight
                boxH.value = windowHeight.value - (n + t + r)
                showFlag.value = true
            }, 500)
            getFactoryInfo()//获取工厂参数

        })

        return {
            hotel,
            tab,
            hotel_arr,
            swipeList,
            boxH,
            active,
            classifyArr,
            swipeIndex,
            showFlag,
            onChange,
            toLink,
            onClickLeft,
            onClickTab,
            checkTabbar,
            clickBack,
            clickNext,
        }
    },
};
</script>
<style lang="less" scoped>
.bg {
    background-color: rgb(236, 236, 236);
}

ul {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0.2rem 0.6rem;
    box-sizing: border-box;
}

.hotelItem {
    width: 25%;
    height: 33.33%;

    div {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #4095E5;
        color: #fff;
        border-radius: 0.05rem;
        font-size: 0.15rem;
        padding: 0.1rem;
        box-sizing: border-box;
    }

    box-sizing: border-box;
    padding: 0.2rem;
}

.pageBtn {
    position: absolute;
    width: 0.5rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 99;
}

/deep/ .van-swipe__indicator {
    background-color: #333;

}
</style>

